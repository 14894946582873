import { graphql } from "gatsby"
import SbEditable from "storyblok-react"

import useStoryblok from "../lib/storyblok"
import DynamicComponent from "../components/storyblok/dynamicComponent"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const IndexPage = ({ data, location }) => {
	let story = data.storyblokEntry
	story = useStoryblok(story, location)

	const components = story.content.body.map(blok => {
		return <DynamicComponent blok={blok} key={blok._uid} />
	})

	return (
		<Layout>
			<SbEditable content={story.content}>
				<Seo title="Home" />
				<h1>{story.content.title}</h1>
				{components}
			</SbEditable>
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
	query HomeQuery {
		storyblokEntry(full_slug: { eq: "home" }) {
			content
			name
		}
	}
`
